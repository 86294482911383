export default [
  {
    item: "Custom web design",
  },
  {
    item: "Web development",
  },
  {
    item: "No-code needed backend",
  },
  {
    item: "Digital strategy",
  },
  {
    item: "WordPress CMS",
  },
  {
    item: "Headless CMS",
  },
  {
    item: "JAMstack websites",
  },
  {
    item: "Progressive web app",
  },
  {
    item: "App Development",
  },
  {
    item: "Static site generators",
  },
  {
    item: "Gatsby Websites",
  },
  {
    item: "User experience",
  },
  {
    item: "User interface",
  },
  {
    item: "Copywriting",
  },
  {
    item: "Animation",
  },
  {
    item: "Security",
  },
  {
    item: "E-commerce websites",
  },
  {
    item: "Membership",
  },
  {
    item: "Ongoing support",
  },
]
