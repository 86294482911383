import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const getData = graphql`
  {
    pencil: file(relativePath: { eq: "design-pencil.png" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Pencil = props => {
  const data = useStaticQuery(getData)
  return (
    <>
      <Img
        fluid={data.pencil.childImageSharp.fluid}
        alt="Don't know Where to start? Switchback Creative"
      />
    </>
  )
}

export default Pencil
