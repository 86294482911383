import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"

import { headlineTwo, buttonOne, fontSizer } from "../../../Utilities"
import StandardWrapper from "../../Shared/StandardWrapper"
import TBLogo from "./TBLogo"
import SwitchbackTB from "../../../Elements/Images/Logos/SwitchbackTB"
import BodyCopy from "../../Shared/BodyCopy"
import CloudLightBlue from "../../../Elements/Images/CloudLightBlue"

const getData = graphql`
  {
    tbLogos: allWordpressWpTrailblazer(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          id
          acf {
            _swb_link
            _swb_logo {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const TrailblazerSection = styled.section`
  position: relative;
  padding: 5rem 0;

  .tb-cloud {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotateX(-180deg);
    z-index: -1;
  }

  .intro-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .tb-logo {
      align-self: center;
      width: 100%;
      max-width: 30rem;
      margin: 2rem auto;

      @media (min-width: 768px) {
        width: calc(30%);
        max-width: 40rem;
        margin: auto;
        margin-bottom: 5rem;
      }

      @media (min-width: 1025px) {
        width: calc(30%);
        margin: 0;
      }
    }

    .tb-link-btn {
      margin-top: 5rem;
      margin-bottom: 5rem;
      text-align: center;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      @media (min-width: 1025px) {
        text-align: left;
      }

      a {
        ${buttonOne};
      }
    }

    .tb-content {
      align-self: center;
      width: 100%;

      @media (min-width: 768px) {
        width: calc(70% - 7.5rem);
        margin-right: 0;
        margin-left: 7.5rem;
      }

      @media (min-width: 1025px) {
        width: calc(70% - 20rem);
        margin-right: 12.5rem;
        margin-left: 7.5rem;
      }

      p {
        color: #585858;
      }
    }
  }

  .winners-section {
    width: 100%;
    padding-top: 5rem;

    &__title {
      h3 {
        ${headlineTwo};
        ${fontSizer(4, 5.4, 76.8, 110, 4)}
        margin: 0;
        color: #5c5c5c;
        text-align: center;

        @media (min-width: 768px) {
          text-align: left;
        }
      }
    }
  }

  .logos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const Trailblazer = () => {
  const data = useStaticQuery(getData)
  const { tbLogos } = data
  return (
    <TrailblazerSection>
      <StandardWrapper>
        <div className="intro-section">
          <div className="tb-logo">
            <SwitchbackTB />
            <div className="tb-link-btn">
              <Link to="/trailblazer-fund/">Learn More</Link>
            </div>
          </div>
          <div className="tb-content">
            <BodyCopy content="<p>Switchback Creative started the TrailBlazer Fund to help NON-PROFITS and CHARITIES doing amazing work in their community.</p> <p>It is awarded yearly to a deserving winner and is valued at $10,000 worth of branding or web development services.</p>" />
          </div>
        </div>
        <div className="winners-section">
          <div className="winners-section__title">
            <h3>Past Winners</h3>
          </div>
          <div className="logos-container">
            {tbLogos.edges.map(logo => {
              return <TBLogo key={logo.node.id} logo={logo.node} />
            })}
          </div>
        </div>
      </StandardWrapper>
      <div className="tb-cloud">
        <CloudLightBlue />
      </div>
    </TrailblazerSection>
  )
}

export default Trailblazer
