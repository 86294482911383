import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import { buttonTwo, bodyCopy } from "../../../Utilities"

const PartnerStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  .partner-logo {
    width: 100%;
    max-width: 17.5rem;
    margin: 0 auto;

    @media (min-width: 768px) {
      width: calc(20%);
      max-width: 17.5rem;
      margin-left: 5%;
    }
  }

  .partner-content {
    width: 100%;

    @media (min-width: 768px) {
      width: 75%;
    }
  }

  .partner-name {
    h3 {
      ${bodyCopy};
      margin: 0;
      margin-bottom: 1rem;
      font-weight: bold;

      @media (min-width: 768px) {
        margin: 0;
      }
    }
  }

  .partner-description {
    p {
      ${bodyCopy};
    }
  }

  .partner-link {
    margin-top: 5rem;

    @media (min-width: 768px) {
      margin: 0;
    }
    a {
      ${buttonTwo};
    }
  }
`

const Partner = ({ data }) => {
  return (
    <PartnerStyled>
      <div className="partner-logo">
        <Img
          fluid={data.acf._swb_logo.localFile.childImageSharp.fluid}
          alt={data.title}
        />
      </div>
      <div className="partner-content">
        <div className="partner-name">
          <h3>{data.title}</h3>
        </div>
        <div
          className="partner-description"
          dangerouslySetInnerHTML={{ __html: data.acf._swb_description }}
        />
        <div className="partner-link">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={data.acf._swb_partner_link}
          >
            Visit Site
          </a>
        </div>
      </div>
    </PartnerStyled>
  )
}

export default Partner
