import React from "react"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const CloudLightBlueStyled = styled(BgImg)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
`

const getData = graphql`
  {
    cloudLightBlue: file(relativePath: { eq: "cloud-lightblue.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const CloudLightBlue = props => {
  const data = useStaticQuery(getData)
  const imageData = data.cloudLightBlue.childImageSharp.fluid
  return (
    <CloudLightBlueStyled Tag="div" fluid={imageData}></CloudLightBlueStyled>
  )
}

export default CloudLightBlue
