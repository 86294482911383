import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import WebsiteHero from "../../../Elements/Images/HeroWebsite"
import Usb from "../../../Elements/Images/Usb"
import {
  standardWrapper,
  headlineOne,
  bodyCopy,
  colors,
  buttonOne,
  fontSizer,
} from "../../../Utilities/"

import webServicesItems from "./ServicesData/WebsiteData"

import IconWebDev from "../../../Elements/Images/IconWebDev"

const WebsiteDevelopmentSection = styled.section`
  position: relative;

  .main-body-icon {
    width: 100%;
    max-width: 75rem;
    margin: 0 auto 2rem;

    &__container {
      width: 10rem;
    }
  }

  .main-title-container {
    position: relative;
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }

    h2 {
      ${headlineOne};
      margin-top: 0;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .main-body-container {
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }

    p {
      ${bodyCopy}
      color: #fff;
    }
  }

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 5;
  }

  .hero-stripe {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3.2rem;
    background: ${colors.colorPrimary};
    z-index: 25;
  }

  .services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(64% - 4rem);
      margin-right: 4rem;
    }

    @media (min-width: 1025px) {
      width: calc(66.66% - 4rem);
      margin-right: 4rem;
    }

    li {
      ${bodyCopy};
      width: calc(50% - 1.5rem);
      margin: 1rem 0.75rem;
      border-bottom: 0.1rem solid ${colors.colorPrimary};
      font-size: 1.5rem;

      @media (min-width: 375px) {
        ${fontSizer(1.8, 2, 76.8, 110, 1.7)}
      }

      @media (min-width: 768px) {
        width: calc(50% - 6rem);
        margin: 1rem 3rem;
      }
    }
  }

  .services-link {
    width: calc(100%);
    margin-left: 0;
    padding-bottom: 15rem;

    @media (min-width: 768px) {
      width: calc(36%);
      margin-left: 0;
      padding-bottom: 0;
    }

    @media (min-width: 1025px) {
      width: calc(33.33% - 4rem);
      margin-left: 4rem;
    }

    .services-btn {
      margin-top: 5rem;

      a {
        ${buttonOne};
        ${fontSizer(1.8, 2, 76.8, 110, 2)};
      }
    }
  }

  .services-image {
    position: absolute;
    bottom: 7.5rem;
    right: -1rem;
    width: calc(36.2rem / 1.5);
    height: calc(14.4rem / 1.5);

    @media (min-width: 768px) {
      bottom: 25rem;
      width: calc(36.2rem / 1.5);
      height: calc(14.4rem / 1.5);
    }

    @media (min-width: 1025px) {
      bottom: 18rem;
      width: calc(36.2rem / 1.1);
      height: calc(14.4rem / 1.1);
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  ${standardWrapper}
  z-index: 10;

  &.hero-wrapper {
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }
  }
`

const MainContentIcon = styled.div`
  position: absolute;
  top: -2rem;
  left: -12.5rem;
  width: 10rem;
  height: 10rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const ServicesListSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 5rem 0;
`

const WebsiteDevelopment = () => {
  return (
    <WebsiteDevelopmentSection>
      <WebsiteHero>
        <Wrapper className="hero-wrapper">
          <div className="main-body-icon">
            <div className="main-body-icon__container">
              <IconWebDev />
            </div>
          </div>
          <div className="main-title-container">
            <h2>Website Development</h2>
            <MainContentIcon />
          </div>
          <div className="main-body-container">
            <p>
              Imagine if you had a sales person that always said the right
              thing, worked 24/7 and always looked good. A good website does
              that; it’s the visual representation of your brand’s story.
              Effective interactive solutions involve huge effort behind the
              screens to create clean code and modern functionality that works.
            </p>
            <p>
              Using modern technologies we craft custom web solutions, NEVER
              using templates, to build fully responsive, user friendly web
              experiences. Doing this right will mean that your customer has a
              seamless experience and can get to the point.{" "}
            </p>
          </div>
        </Wrapper>
        <div className="hero-overlay" />
        <div className="hero-stripe" />
      </WebsiteHero>
      <Wrapper className="list-items-wrapper">
        <ServicesListSection>
          <ul className="services-list">
            {webServicesItems.map((item, index) => {
              return <li key={index}>{item.item}</li>
            })}
          </ul>
          <div className="services-link">
            <div className="services-btn">
              <Link to="/services/web-development">The Process + Tech</Link>
            </div>
          </div>
        </ServicesListSection>
      </Wrapper>
      <div className="services-image">
        <Usb />
      </div>
    </WebsiteDevelopmentSection>
  )
}

export default WebsiteDevelopment
