export default [
  {
    item: "Brand development",
  },
  {
    item: "Logo design",
  },
  {
    item: "Brand guidelines",
  },
  {
    item: "Graphic design",
  },
  {
    item: "Brochures",
  },
  {
    item: "Business cards",
  },
  {
    item: "Packaging",
  },
  {
    item: "Key messaging",
  },
  {
    item: "Illustration",
  },
  {
    item: "Infographics",
  },
  {
    item: "Annual reports",
  },
  {
    item: "Culture books",
  },
  {
    item: "Promotional materials",
  },
  {
    item: "Social media graphics",
  },
]
