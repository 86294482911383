import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BrandingHero from "../../../Elements/Images/HeroBranding"
import Pencil from "../../../Elements/Images/Pencil"
import BandingServiceItems from "./ServicesData/BandingData"
import {
  standardWrapper,
  headlineOne,
  bodyCopy,
  colors,
  buttonOne,
  fontSizer,
} from "../../../Utilities/"

import IconBranding from "../../../Elements/Images/IconBranding"

const DesignBrandingSection = styled.section`
  position: relative;

  .main-body-icon {
    width: 100%;
    max-width: 75rem;
    margin: 0 auto 2rem;

    &__container {
      width: 10rem;
    }
  }

  .main-title-container {
    position: relative;
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }

    h2 {
      ${headlineOne};
      margin-top: 0;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .main-body-container {
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }

    p {
      ${bodyCopy}
      color: #fff;
    }
  }

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 5;
  }

  .hero-stripe {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3.2rem;
    background: ${colors.colorSecondary};
    z-index: 25;
  }

  .services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(66.66% - 4rem);
      margin-right: 4rem;
    }

    li {
      ${bodyCopy};
      width: calc(50% - 1.5rem);
      margin: 1rem 0.75rem;
      border-bottom: 0.1rem solid ${colors.colorSecondary};
      font-size: 1.5rem;

      @media (min-width: 375px) {
        ${fontSizer(1.8, 2, 76.8, 110, 1.7)}
      }

      @media (min-width: 768px) {
        width: calc(50% - 6rem);
        margin: 1rem 3rem;
      }
    }
  }

  .services-link {
    position: relative;
    width: calc(100%);
    padding-top: 7.5rem;

    @media (min-width: 768px) {
      width: calc(33.33% - 4rem);
      padding-top: 0;
      margin-left: 4rem;
    }

    .services-btn {
      margin-top: 5rem;

      a {
        ${buttonOne};
      }
    }

    .services-image {
      position: absolute;
      top: 20%;
      right: -20rem;
      width: calc(56.1rem / 1.75);
      height: calc(19.5rem / 1.75);

      @media (min-width: 375px) {
        width: calc(56.1rem / 1.5);
        height: calc(19.5rem / 1.5);
      }

      @media (min-width: 768px) {
        top: 40%;
        right: -25rem;
        width: calc(56.1rem / 1.25);
        height: calc(19.5rem / 1.25);
      }
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  ${standardWrapper}
  z-index: 10;

  &.hero-wrapper {
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }
  }
`

const MainContentIcon = styled.div`
  position: absolute;
  top: -2rem;
  left: -12.5rem;
  width: 10rem;
  height: 10rem;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const ServicesListSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 5rem 0;
`

const DesignBranding = () => {
  return (
    <DesignBrandingSection>
      <BrandingHero>
        <Wrapper className="hero-wrapper">
          <div className="main-body-icon">
            <div className="main-body-icon__container">
              <IconBranding />
            </div>
          </div>
          <div className="main-title-container">
            <h2>Design &#43; Branding</h2>
            <MainContentIcon />
          </div>
          <div className="main-body-container">
            <p>
              Your brand is the face of your company, whether you are new
              company or need to revive your existing brand, we will walk you
              through a process to create a long term vision and identity for
              your brand.
            </p>
            <p>
              Storytelling starts with a vision and logo. <br />
              What will your first impression to the world be?
            </p>
          </div>
        </Wrapper>
        <div className="hero-overlay" />
        <div className="hero-stripe" />
      </BrandingHero>
      <Wrapper>
        <ServicesListSection>
          <ul className="services-list">
            {BandingServiceItems.map((item, index) => {
              return <li key={index}>{item.item}</li>
            })}
          </ul>
          <div className="services-link">
            <div className="services-btn">
              <Link to="/services/logo-designs">Logo Work</Link>
            </div>
            <div className="services-image">
              <Pencil />
            </div>
          </div>
        </ServicesListSection>
      </Wrapper>
    </DesignBrandingSection>
  )
}

export default DesignBranding
