import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HeaderScene from "../components/PageParts/Services/HeaderScene"
import Intro from "../components/PageParts/Services/Intro"
import CaseLink from "../components/PageParts/Services/CaseLink"
import DesignBranding from "../components/PageParts/Services/DesignBranding"
import WebsiteDevelopment from "../components/PageParts/Services/WebsiteDevelopment"
import MarketingAdvertising from "../components/PageParts/Services/MarketingAdvertising"
import Trailblazer from "../components/PageParts/Services/Trailblazer"
import Testimonials from "../components/Shared/Testimonials"
import Partners from "../components/PageParts/Services/Partners"

const ServicesPage = props => (
  <Layout location={props.location.pathname}>
    <SEO
      title="Switchback Creative - Website & Graphic Design - Branding & Marketing"
      description="Switchback Creative services we provide Brand Identity, Web Development, Web Design, Graphic Design, SEO + Social Media, Marketing and Speaking + Education."
      location={props.location.pathname}
      pageImg={props.data.metaImage.publicURL}
    />
    <HeaderScene />
    <Intro />
    <CaseLink />
    <DesignBranding />
    <WebsiteDevelopment />
    <MarketingAdvertising />
    <Trailblazer />
    <Testimonials />
    <Partners />
  </Layout>
)

export const servicesQuery = graphql`
  {
    metaImage: file(relativePath: { eq: "switchback-whatwedo-meta.jpg" }) {
      publicURL
    }
  }
`

export default ServicesPage
