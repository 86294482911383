import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Gatsby handled Images. //
import MarketingHero from "../../../Elements/Images/HeroMarketing"
import CellPhone from "../../../Elements/Images/CellPhone"
import {
  standardWrapper,
  headlineOne,
  bodyCopy,
  colors,
  buttonOne,
  fontSizer,
} from "../../../Utilities/"

import marketingServicesItems from "./ServicesData/MarketingData"

import IconMarketing from "../../../Elements/Images/IconMarketing"

const MarketingSection = styled.section`
  position: relative;

  .main-body-icon {
    width: 100%;
    max-width: 75rem;
    margin: 0 auto 2rem;

    &__container {
      width: 10rem;
    }
  }

  .main-title-container {
    position: relative;
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }

    h2 {
      ${headlineOne};
      margin-top: 0;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .main-body-container {
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }

    p {
      ${bodyCopy}
      color: #fff;
    }
  }

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 5;
  }

  .hero-stripe {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3.2rem;
    background: ${colors.colorAccent};
    z-index: 25;
  }

  .services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(66.66% - 4rem);
      margin-right: 4rem;
    }

    li {
      ${bodyCopy};
      width: calc(50% - 1.5rem);
      margin: 1rem 0.75rem;
      border-bottom: 0.1rem solid ${colors.colorAccent};
      font-size: 1.5rem;

      @media (min-width: 375px) {
        ${fontSizer(1.8, 2, 76.8, 110, 1.7)}
      }

      @media (min-width: 768px) {
        width: calc(50% - 6rem);
        margin: 1rem 3rem;
      }
    }
  }

  .services-link {
    width: calc(100%);
    padding-bottom: 10rem;

    @media (min-width: 768px) {
      width: calc(33.33% - 4rem);
      margin-left: 4rem;
      padding-bottom: 0;
    }

    .services-btn {
      margin-top: 5rem;

      a {
        ${buttonOne};
      }
    }

    .services-image {
      position: absolute;
      top: auto;
      right: -6rem;
      bottom: 9rem;
      width: calc(40.5rem / 3.5);
      height: calc(78rem / 3.5);
      transform: rotate(-10deg);

      @media (min-width: 375px) {
        right: -5rem;
        bottom: -3rem;
        width: calc(40.5rem / 3);
        height: calc(78rem / 3);
      }

      @media (min-width: 768px) {
        top: 40%;
        right: -5rem;
        width: calc(40.5rem / 2.5);
        height: calc(78rem / 2.5);
        bottom: auto;
        transform: rotate(-15deg);
      }

      @media (min-width: 1025px) {
        top: 38%;
        right: -7.5rem;
        bottom: auto;
        transform: rotate(-15deg);
      }
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  ${standardWrapper}
  z-index: 10;

  &.hero-wrapper {
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }
  }
`

const MainContentIcon = styled.div`
  position: absolute;
  top: -2rem;
  left: -12.5rem;
  width: 10rem;
  height: 10rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const ServicesListSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 5rem 0;
`

const MarketingAdvertising = () => {
  return (
    <MarketingSection>
      <MarketingHero>
        <Wrapper className="hero-wrapper">
          <div className="main-body-icon">
            <div className="main-body-icon__container">
              <IconMarketing />
            </div>
          </div>
          <div className="main-title-container">
            <h2>Marketing &#43; Advertising</h2>
            <MainContentIcon />
          </div>
          <div className="main-body-container">
            <p>
              Strong marketing sets the stage for big growth opportunities for
              your business. We will help you create a proactive approach to
              tackling the big things in your business that need planning and
              execution.
            </p>
            <p>
              Developing great advertising extends beyond placing an ad in the
              newspaper or on Facebook—it is tapping into the pains of your
              customer and showing the ways you help. Further to that we will
              help you spend wisely by sifting through all of the possibilities
              of what to do online, offline and in the community. We create a
              road map for your marketing, setting checkpoints along the way to
              measure against.
            </p>
          </div>
        </Wrapper>
        <div className="hero-overlay" />
        <div className="hero-stripe" />
      </MarketingHero>
      <Wrapper>
        <ServicesListSection>
          <ul className="services-list">
            {marketingServicesItems.map((item, index) => {
              return <li key={index}>{item.item}</li>
            })}
          </ul>
          <div className="services-link">
            <div className="services-btn">
              <Link to="/contact">Level up now</Link>
            </div>
            <div className="services-image">
              <CellPhone />
            </div>
          </div>
        </ServicesListSection>
      </Wrapper>
    </MarketingSection>
  )
}

export default MarketingAdvertising
