import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Partner from "./Partner"
import StandardWrapper from "../../Shared/StandardWrapper"
import { headlineOne } from "../../../Utilities"

const getData = graphql`
  {
    partners: allWordpressWpPartners(sort: { fields: [date], order: [ASC] }) {
      edges {
        node {
          id
          title
          acf {
            _swb_description
            _swb_partner_link
            _swb_logo {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const PartnersSection = styled.section`
  padding: 5rem 0;

  .partner-title {
    width: 100%;
    margin-bottom: 3rem;

    h2 {
      ${headlineOne};
      color: #3a3b3b;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .partners-container {
    width: 100%;
  }
`

const Partners = () => {
  const data = useStaticQuery(getData)
  const {
    partners: { edges: partners },
  } = data
  return (
    <PartnersSection>
      <StandardWrapper>
        <div className="partner-title">
          <h2>Good Stories, need good partners</h2>
        </div>
        <div className="partners-container">
          {partners.map(partner => {
            return <Partner key={partner.node.id} data={partner.node} />
          })}
        </div>
      </StandardWrapper>
    </PartnersSection>
  )
}

export default Partners
