import React from "react"
import styled from "styled-components"

import StandardWrapper from "../../Shared/StandardWrapper"
import ScriptTitle from "../../Shared/ScriptTitle"
import BodyCopy from "../../Shared/BodyCopy"

const IntroStyled = styled.div`
  position: relative;
  margin-top: -5rem;
  z-index: 25;

  @media(min-width: 768px) {
    margin-top: -2.5rem;
  }

  @media(min-width: 1025px) {
    margin-top: -5vw;
  }

  .introWrapper {
    @media(min-width: 768px) {

    }
    @media(min-width: 1025px) {
      max-width: 65vw;
      margin-left: 10vw;
      padding: 0;
    }
  }

  .intro-content {
    width: 100%;
    text-align: center;

    @media(min-width: 768px) {
      text-align: left;
    }
  }

`

const Intro = () => {
  return (
    <IntroStyled>
      <StandardWrapper modifierClass="introWrapper">
        <div className="intro-content">
          <ScriptTitle title="<h2 class='title-script'>Starting with your customer, the hero of your story, we utilize the tools that work best to share your business with your community and region.</h2>" />
          <BodyCopy content="<p>It is our job to discover the stories that will compel your hero to act. Your hard work has set the stage for your business. Our job is to determine the checkpoints that match with the time and place where your customers need what you offer.</p><p>Most people think that they have to figure this out on their own, that they have to find the time to do their own branding and marketing, then hire out any specific executions later on.</p><p>Most people think that marketing is a Facebook ad, a free giveaway or simply having your contact information online. You aren’t most people, you strive for better!</p>" />
        </div>
      </StandardWrapper>
    </IntroStyled>
  )
}

export default Intro