import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import ScriptCallOut from "../../Shared/ScriptCallOut"
import StandardWrapper from "../../Shared/StandardWrapper"
import { buttonOne } from "../../../Utilities"

const CaseLinkSection = styled.section`
  padding: 0 0 4rem;

  @media (min-width: 1025px) {
    padding: 5rem 0;
  }

  .case-title {
    margin: 0 auto 5rem;

    @media (min-width: 1025px) {
      max-width: 80rem;
      margin: 0 auto 5rem;
    }

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0 0 5rem;
    }
  }

  .case-btn {
    width: 100%;
    text-align: center;

    a {
      ${buttonOne};
    }
  }
`

const CaseLink = () => {
  return (
    <CaseLinkSection>
      <StandardWrapper>
        <div className="case-title">
          <ScriptCallOut content="<h2 class='call-out-text'>We are here to guide you through the process, finding the best customers for your business.</h2>" />
        </div>
        <div className="case-btn">
          <Link to="/case-studies">Case Studies</Link>
        </div>
      </StandardWrapper>
    </CaseLinkSection>
  )
}

export default CaseLink
