export default [
  {
    item: "Marketing strategy",
  },
  {
    item: "Advertising - all types",
  },
  {
    item: "Campaign development",
  },
  {
    item: "Newsletter campaigns",
  },
  {
    item: "Social media marketing",
  },
  {
    item: "Social media management",
  },
  {
    item: "Content writing",
  },
  {
    item: "Print ads",
  },
  {
    item: "Event marketing",
  },
  {
    item: "Email marketing",
  },
]
